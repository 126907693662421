import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import ReactSVG from 'react-svg'
import Burger from './Burger'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { useLocation } from '@reach/router'

const Navbar = () => {
  const location = useLocation()
  const pathname = location.pathname
  const [open, setOpen] = useState(false)
  const [navBar] = useState(false)

  useEffect(() => {
    if (open) {
      document.body.style.overflowX = 'hidden'
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'initial'
    }
  })
  return (
    <>
      <header className='header'>
        <nav className='navigation'>
          <div className='navigation__inner container'>
            <Link className='navigation__inner--logo' to='/'>
              <ReactSVG src='/img/svg/shi_logo.svg'></ReactSVG>
              <div className='sr-only'>Home Page</div>
            </Link>

            <div
              className={
                'navigation__list' +
                ' ' +
                (open ? 'navigation__list--open' : 'navigation__list--closed')
              }>
              <div className='navigation__list--left'>
                {pathname === '/' ? (
                  <a
                    onClick={() => {
                      setOpen(false)
                      scrollTo('#services')
                    }}
                    className='nav-link cursor-pointer'>
                    Services
                  </a>
                ) : (
                  <Link to='/#services' className='nav-link cursor-pointer'>
                    Services
                  </Link>
                )}

                <Link className='nav-link' to='/team/'>
                  Team
                </Link>
                <Link className='nav-link' to='/news/'>
                  News
                </Link>
                <Link className='nav-link' to='/policy/'>
                  Policy
                </Link>
              </div>
              <div className='navigation__list--right'>
                <Link to='/awards/' className='nav-link'>
                  Awards
                </Link>
                <Link className='nav-link' to='/careers/'>
                  Careers
                </Link>
                <a
                  onClick={() => {
                    setOpen(false)
                    scrollTo('#workHours')
                  }}
                  className='btn btn--standard btn--green cursor-pointer'>
                  Work Hours
                </a>
              </div>
            </div>
            <Burger main={true} navBar={navBar} setOpen={setOpen} open={open} />
          </div>
        </nav>
      </header>
    </>
  )
}

export default Navbar
