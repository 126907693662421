import React from 'react'
import ReactMarkdown from 'react-markdown'

const InfoCard = ({ id, className, source, children }) => {
  return (
    // <div className={className}>
    <div id={id} className='info-card'>
      <ReactMarkdown source={source}></ReactMarkdown> {children}
    </div>
    // </div>
  )
}

export default InfoCard
