import React, { useEffect } from 'react'
import Footer from '@/components/Footer'
import Navbar from '@/components/Navbar'

import DefaultSeo from '@/components/SEO/DefaultSeo'

const TemplateWrapper = ({ children }) => {
  return (
    <>
      <DefaultSeo />
      <div className='h-screen relative overflow-x-hidden'>
        <Navbar></Navbar>
        <main className='wrapper'>{children}</main>
        <Footer />
      </div>
    </>
  )
}

export default TemplateWrapper
