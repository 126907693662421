import React from 'react'

const Burger = ({ open, setOpen, navBar, main }) => {
  return (
    <div
      className={open ? 'burger burger-open' : 'burger burger-closed'}
      open={open}
      onClick={() => setOpen(!open)}>
      <>
        <span
          style={{
            backgroundColor: navBar
              ? open
                ? '#fff'
                : '#394655'
              : open
              ? '#fff'
              : '#fff',
          }}
        />
        <span
          style={{
            backgroundColor: navBar
              ? open
                ? '#fff'
                : '#394655'
              : open
              ? '#fff'
              : '#fff',
          }}
        />
        <span
          style={{
            backgroundColor: navBar
              ? open
                ? '#fff'
                : '#394655'
              : open
              ? '#fff'
              : '#fff',
          }}
        />
      </>
    </div>
  )
}

export default Burger
