import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import ReactSVG from 'react-svg'
import BackgroundImage from 'gatsby-background-image'
import InfoCard from '@/components/InfoCard/InfoCard'
import scrollTo from 'gatsby-plugin-smoothscroll'
const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "footer" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              background {
                childImageSharp {
                  fluid(quality: 42, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              heading

              socials {
                instagram
                facebook
                twitter
              }
              hours
            }
          }
        }
      }
      contact: allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "contact" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              phone
              adress
              email
              booking
            }
          }
        }
      }
    }
  `)

  const footerData = data.allMarkdownRemark.edges[0].node.frontmatter
  const contactData = data.contact.edges[0].node.frontmatter

  return (
    <footer className='footer' id='footer'>
      <BackgroundImage
        style={{ position: 'absolute' }}
        fluid={footerData.background.childImageSharp.fluid}
        className='footer__background'
      />
      <div className='container'>
        <h2 className='text-center lg:text-left'>{footerData.heading}</h2>
        <div className='footer__contact'>
          <div className='footer__contact__item'>
            <a className='icon' href={`tel:${contactData.phone}`}>
              <ReactSVG src='/img/svg/icon_phone.svg' />
              <div className='sr-only'>Call Shi Hair</div>
            </a>

            <div className='content'>
              <h5 className='uppercase leading-none'>Call Us</h5>
              <p>{contactData.phone}</p>
            </div>
          </div>
          <div className='footer__contact__item'>
            <a className='icon' href={`mailto:${contactData.email}`}>
              <ReactSVG src='/img/svg/icon_mail.svg' />
              <div className='sr-only'>Email Shi Hair</div>
            </a>

            <div className='content'>
              <h5 className='uppercase leading-none'>Email</h5>
              <p>{contactData.email}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='footer__wrap container text-center pt-8 lg:pt-24'>
        <InfoCard id='workHours' source={footerData.hours}>
          <a href='#onlinebookings' className='btn btn__green'>
            Book a visit
          </a>
        </InfoCard>
        <div className='flex justify-between items-center flex-col lg:flex-row'>
          <div className='footer__list'>
            <ul className='footer__list--half'>
              <li className='footer__list--item'>
                <a
                  className='cursor-pointer'
                  onClick={() => scrollTo('#services')}>
                  Services
                </a>
              </li>
              <li className='footer__list--item'>
                <Link to='/team/'>Team</Link>
              </li>
              <li className='footer__list--item'>
                <Link to='/news/'>News</Link>
              </li>
            </ul>
            <ul className='footer__list--half'>
              <li className='footer__list--item'>
                <Link to='/careers/'>Careers</Link>
              </li>
              <li className='footer__list--item'>
                <Link to='/awards/'>Awards</Link>
              </li>
              <li className='footer__list--item'>
                <Link to='/policy/'>Policy</Link>
              </li>
            </ul>
          </div>
          <div className='footer__social'>
            <a
              rel='noopener noreferrer'
              target='_blank'
              href='https://play.google.com/store/apps/details?id=com.phorest.shihairdressing'>
              <ReactSVG src='/img/svg/gplay.svg' />
              <div className='sr-only'>
                Download ShiHair app on Google Play Store
              </div>
            </a>
            <a
              rel='noopener noreferrer'
              target='_blank'
              href='https://apps.apple.com/us/app/shi-hairdressing/id1497870046'>
              <ReactSVG src='/img/svg/appstore.svg' />
              <div className='sr-only'>Download ShiHair app on App Store</div>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
